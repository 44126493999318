import React from 'react'
import { useState, useEffect } from 'react'
import CountUp from 'react-countup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import reqwest from 'reqwest'
// import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import Register from '../Register'

import styles from './main.module.scss'
import firebase from '../../firebase.js'

const CHANGE_ORG_LINK = "https://www.change.org/p/vanderbilt-university-bring-back-swipes-for-a-cause"

const GET_DONATION_LINK = "https://vandy.link/sfac/donate"

const Main = () => {
  const [isLandingShown, setIsLandingShown] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)

  const [meals, setMeals] = useState(0)
  const [users, setUsers] = useState(0)

  const [potentialMeals, setPotentialMeals] = useState(0)

  const [duration, setDuration] = useState(0)

  const myEndFunction = () => {
    setIsLandingShown(true)
  }

  //Handle animation end for panel moving upward
  var x = document.getElementById('mainContainer')
  if (x != null) {
    x.addEventListener('animationend', myEndFunction)
  }

  async function fetchMealsDonated () {
    firebase
      .database()
      .ref('mealsDonated')
      .once(
        'value',
        snap => {
          setMeals(snap.val())
        },
        function (_err) {
          setMeals(100)
        }
      )
  }

  //Function to calculate number of meals missed due to student accountability
  async function getPotentialMeals (){
    //var now = Date.now()
    var now = Date.parse('November 20, 2020 21:30:00')

    var shutdown = Date.parse('October 27, 2020 21:30:00')

    const diffTime = Math.abs(now - shutdown);

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    //378 is estimated meals per day that we would have donated
    const missedMeals = diffDays * 378

    setPotentialMeals(missedMeals)
  }

  function getCountdown (){
    //var now = Date.now()
    var now = Date.now()

    var launch = Date.parse('September 13, 2021 9:13:00')

    const diffTime = Math.abs(launch - now);

    const diffSecs= Math.ceil(diffTime / (1000)); 

    return(diffSecs)
    // setDuration(diffSecs)

  }


  const minuteSeconds = 60;
  const hourSeconds = 3600;
  const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6
};

const renderTime = (dimension, time) => {
  return (
    <div className={styles.timeWrapper}>
      <div className={styles.time}>{time}</div>
      <div className = {styles.dimension}>{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;


  const startTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = Date.parse('September 13, 2021 9:13:00')/1000; // use UNIX timestamp in seconds

  const remainingTime = endTime - startTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  //Call cloud function for number of current users
  async function fetchNumUsers () {
    let _r = await reqwest({
      url: 'https://us-central1-vandyfood.cloudfunctions.net/getNumUsers',
      crossOrigin: true,
      method: 'GET',
      data: {}
    });

    setUsers(_r.response);
  }

  useEffect(() => {
    fetchMealsDonated()
    fetchNumUsers()
    getPotentialMeals()
  }, []);

  return (
    <div className={styles.globalFont}>
      {!isLandingShown && (
        <Container
          className={styles.mainContainer}
          id='mainContainer'
        >
          <Row>
            <Col className={styles.colCenter}>
              <img
                className={styles.tempLogo}
                src={
                  'https://firebasestorage.googleapis.com/v0/b/vandyfood.appspot.com/o/SwipeForACause.png?alt=media&token=e784956f-c2c9-4932-905d-87a17925eca9'
                }
                alt='Vanderbilt Logo'
              ></img>
            </Col>
          </Row>
          {
            <Row>
              <Col className={styles.colCenter}>
                <center>
                  <div className={styles.mainTitle}>
                    <b>Swipes For A Cause</b>
                  </div>
                </center>
              </Col>
            </Row>
          }
          <Row className={styles.resWidth}>
            <Col className={styles.openLetterHeader}>
              <center>
              Donations Now Live!
              </center>
            </Col>
          </Row>


          {/* <Row className={styles.resWidth}>
            <Col className={styles.openLetter}>
            <p className={styles.newText}>
              <center>Check back in for our launch soon!</center>
              </p>
            </Col>
          </Row> */}

          {/* <Row>
            <Col className = {styles.timers}>
              <div className={styles.countdown}>
            <CountdownCircleTimer
        {...timerProps}
        colors={[["#D8AB4C"]]}
        duration={daysDuration}
        initialRemainingTime={remainingTime}
        size={180}
      >
        {({ elapsedTime }) =>
          renderTime("days", getTimeDays(daysDuration - elapsedTime))
        }
      </CountdownCircleTimer>
      </div>
      <div className={styles.countdown}>
      <CountdownCircleTimer
        {...timerProps}
        colors={[["#000000"]]}
        duration={daySeconds}
        initialRemainingTime={remainingTime % daySeconds}
        onComplete={(totalElapsedTime) => [
          remainingTime - totalElapsedTime > hourSeconds
        ]}
        size={180}
      >
        {({ elapsedTime }) =>
          renderTime("hours", getTimeHours(daySeconds - elapsedTime))
        }
      </CountdownCircleTimer>

      </div>
      <div className={styles.countdown}>
      <CountdownCircleTimer
        {...timerProps}
        colors={[["#D8AB4C"]]}
        duration={hourSeconds}
        initialRemainingTime={remainingTime % hourSeconds}
        onComplete={(totalElapsedTime) => [
          remainingTime - totalElapsedTime > minuteSeconds
        ]}
        size={180}
      >
        {({ elapsedTime }) =>
          renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))
        }
      </CountdownCircleTimer>
      </div>
      <div className={styles.countdown}>
      <CountdownCircleTimer
        {...timerProps}
        colors={[["#000000"]]}
        duration={minuteSeconds}
        initialRemainingTime={remainingTime % minuteSeconds}
        onComplete={(totalElapsedTime) => [
          remainingTime - totalElapsedTime > 0
        ]}
        size={180}
      >
        {({ elapsedTime }) =>
          renderTime("seconds", getTimeSeconds(elapsedTime))
        }
      </CountdownCircleTimer>
      </div>
            </Col>
          </Row> */}

          <Row className={styles.resWidth}>
            <Col className={styles.openLetter}>
            <p className={styles.newText}>
              <center>There are two new ways to donate:</center>
              </p>

              <p className={styles.newText}>
              <center>1. Regular meal swipes can no longer be donated, but <a href = "https://campusdining.vanderbilt.edu/meal-plan-2021-2022/" target = "_blank">Guest Meals</a> can (You have 5 per semester).</center>
              </p>  

              <p className={styles.newText}>
              <center>2. You can also donate meal money directly to the food bank ($1 = 4 meals)!</center>
              </p>

              <p className={styles.newText}>
              <center>Both of these options are available in the <a href="getmobile://">GET App</a> or <a href = {GET_DONATION_LINK} target = "_blank"> online </a> under "Swipes for a Cause"</center>
              </p>
            </Col>
          </Row>

          {/* <Row className={styles.resWidth}>
            <Col className={styles.openLetter}>
              <p className={styles.letterText}>
              <b>It’s clear that Vanderbilt doesn’t like listening to its students.</b>
              </p>
              
              <p className={styles.letterText}>
              During a difficult semester of pandemic learning, time and time again, administrators denied, ignored and otherwise avoided student requests for flexible grading, mental health days, divestment from fossil fuels, free winter break housing for at-risk students and so much more.
              </p>

              <p className={styles.letterText}>
              We as students understand that university operations can be notoriously difficult to navigate and change. Sometimes decision-making is not a perfect process and a lot of complicated factors are involved. Sometimes a communication failure between students and administrators can produce an outcry. Sometimes key players in the decision—like students and community members—are excluded from discussions. 
              </p>

              <p className={styles.letterText}>
              We have been stonewalled by administration too—and this time, it has left tens of thousands of people in Nashville without meals.
              </p>

              <p className={styles.letterText}>
              We are Swipes For A Cause, an organization that allows students to donate their unused meal swipes to people in need. Our organization automatically orders shelf-stable foods from campus Munchie Marts to distribute to local food banks. We are the first program to use daily unused meals instead of the five “guest meals” students get per semester, as previous programs have done. We have over 500 students signed up to donate with us—almost ten percent of Vanderbilt’s entire student body. In under three weeks, we were able to reach 300 donated meals per day, with the potential of expanding to over 800. </p>

              <p className={styles.letterText}>
              If our program had run for an entire semester, we could have provided 70,000 meals to Nashville’s food-insecure population. 
              </p>

              <p className={styles.letterText}>
              And then Vanderbilt ordered us to shut it down.
              </p>

              <p className={styles.letterText}>
              At the start, we figured the allegations they sent us were a simple misunderstanding—administrators worried that we were storing usernames and passwords. If they had talked to us, they would have known that our organization has never had access to student logins and can’t access personal student information. In response to administration’s concerns, we willingly replied with a detailed overview of our organization and technology. We were excited about partnering with administration and helping our community. We had hoped to resume distributing meals quickly. 
              </p>

              <p className={styles.letterText}>
              Over the next couple of weeks, we waited patiently for Vanderbilt to reply to our messages. We hoped that they would state their concerns with our system or agree to meet with us to discuss the program. We gave administration the benefit of the doubt, knowing how busy their schedules could be. That was three months ago, and since then we've sent dozens of emails to administrators across departments to try to schedule a meeting with one of them. Even with staff members and student leaders on our side, administration has willfully and maliciously ignored our pleas for a simple discussion, instead choosing to relentlessly attack us. They sent an email to our users, stating that we did not properly protect student data. They threatened us with disciplinary action should we continue the program, asserting that we introduced risk to student cybersecurity. They questioned our community partners over the phone, pushing them to stop working with us. They ignored our emails and phone calls, only responding when they needed something from us or when they were trying to implicate us in policy violations.
              </p>

              <p className={styles.letterText}>
              All of the administrators we needed to talk to held a meeting behind our backs to talk about us. We were not invited. When we sent an email asking to meet with all of them, we were met with silence. We sent them an agenda and documentation of a proposed solution. Silence.
              </p>

              <p className={styles.letterText}>
              We were so excited to come to a joint solution with Vanderbilt and hoped administration would be equally as enthusiastic. However, after being continually ignored with no end in sight, we feel we have no choice but to publicly appeal to the following administrators: 
              </p>

              <p className={styles.letterText}>
              Mark Brown, Director of Card Services: we've asked you and your department for a meeting in over ten emails. In every single one, you've either ignored us or completely dodged the question. Our program has the potential to feed thousands of hungry families in Nashville. Just allow us to, at the very least, submit an application to officially partner with the meal processing company. This approval would let us work within Vanderbilt’s systems safely and securely, and would likely eliminate all security concerns the administration has raised about our system.
              </p>

              <p className={styles.letterText}>
              David Ter Kuile, Executive Director in Campus Dining: we were told you were supportive of what we were trying to do. Why did you wait weeks to reply to us, and then make the unfounded accusation that our program would lead to students skipping meals? You have data from when we operated, and you know that's just empirically not true. Not only that, but we pay for all of these meals. Let us use them how we want.
              </p>

              <p className={styles.letterText}>
              Mark Bandas, Dean of Students: we've heard so much about you and how you feel about us, but for some reason, it's never been from you directly. Our organization came out of the blue and we know that's hard to deal with, but we've been willing to work with you to come to a solution for months. You've already stopped your students from donating nearly 10,000 meals without saying a word. Give us a chance. 
              </p>

              <p className={styles.letterText}>
              As first-years, we were so excited to come to Vanderbilt. The school’s stated commitment to serving the greater good and Nashville community inspired me. We were confident that this was a place where we could start an initiative, give back to others, and make impactful change. Instead, I’ve seen that I'm a part of an institution that won't even respond to a charity organization that almost ten percent of the student body takes part in. We understand that meals cost real money and compromises will inevitably have to be made—but to come to an agreement that works for everyone, a good-faith discussion must be had.
              </p>

              <p className={styles.letterText}>
              Please, we beg of you. Vanderbilt. Talk to us.
              </p>
            </Col>
          </Row> */}
          
          {/* <Row className={styles.resWidth}>
            <Col className={styles.openLetterSignature}>
              <p>
                Signed, 
              </p>
              </Col>
          </Row> */}

          {/* <Row className={styles.resWidth}>
            <Col className={styles.openLetterSignature}>
              <p>
                The Swipes For A Cause Team 
              </p>
            </Col>
          </Row> */}

          <Row className={styles.resWidth}>
            <Col className={styles.signPetition}>
              <center>
                  <a href={GET_DONATION_LINK} target="_blank">
                    <button className={styles.petitionButton}>Donate Here!</button>
                  </a>
              </center>
            </Col>
          </Row>

          <Row>
              <Col className={styles.colCenternp}>
                  <CountUp className={styles.ib} end={meals} duration={4} delay={0} formattingFn={(num) => { return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}></CountUp>
                  <span className={styles.ib}>&nbsp;meals&nbsp;donated&nbsp;by&nbsp;</span>
                  <CountUp className={styles.ib} end={users} duration={4} delay={0} formattingFn={(num) => { return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}></CountUp>
                  <span className={styles.ib}>&nbsp;students</span>
              </Col>
          </Row>

          <Row>
            <Col className={styles.colCenternp}>
              <a
                  href='#'
                  className={styles.fakeLink}
                  onClick={() => setShowContactModal(true)}
                >
                  Connect with us!
                </a>
            </Col>
          </Row>
        </Container>
      )}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Modal
        show={showContactModal}
        onHide={() => setShowContactModal(false)}
        dialogClassName='modal-90w'
      >
        <Modal.Header closeButton>
          <Modal.Title>Connect with us!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{display: 'flex',justifyContent: 'center'}}>
            <a href="mailto:info@swipesforacause.org" style={{color: 'black'}}>Email<img alt="Email Icon" className={styles.logos} src="https://firebasestorage.googleapis.com/v0/b/vandyfood.appspot.com/o/gmailgold.png?alt=media&amp;token=e903da5f-fd91-4fe4-b37e-24f4f2540c37"/></a>
            <a><img alt="Separator" style={{width: '2.5px', height: '50px'}} src="https://firebasestorage.googleapis.com/v0/b/vandyfood.appspot.com/o/Untitled%20drawing.png?alt=media&token=fc0dd86a-d288-4904-883f-6af53e55f9a8"/></a>
            <a rel="noopener noreferrer" target="_blank" href="https://instagram.com/swipesforacause"style={{color: 'black'}}><img alt="Instagram Icon" className={styles.logos} src="https://firebasestorage.googleapis.com/v0/b/vandyfood.appspot.com/o/instagold.png?alt=media&amp;token=a8a61a77-7bb7-41d7-9f26-b9c887d423ba"/>Instagram</a>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className={styles.colButton}
            onClick={() => setShowContactModal(false)}
            variant='primary'
          >
            Sounds good!
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Main

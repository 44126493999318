import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import reqwest from 'reqwest'
import { useEffect, useState } from 'react'

import styles from './update.module.scss'

const Update = () => {
  const [error, setError] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [sendLoader, setSendLoader] = useState(false);
  const [success, setSuccess] = useState(false);

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  document.title = "Update Account";

  const getState = (place, index) => {
    if (schedule[place].includes(index)) {
      return true;
    }

    return false;
  };

  const setCheckState = (e, place, index) => {
    if (e.target.checked) {
      schedule[place].push(index);
      setSchedule(JSON.parse(JSON.stringify(schedule)));
    } else {
      schedule[place] = schedule[place].filter(item => item !== index)
      if (place === "temporaryBypass") {
        schedule["permanentBypass"] = schedule["permanentBypass"].filter(item => item !== index)
      }

      setSchedule(JSON.parse(JSON.stringify(schedule)));
    }
  }

  useEffect(() => {
    async function efRun() {
      console.log("in");
      if (window.location.search && 
            window.location.search.split("?r=").length === 2 && 
            window.location.search.split("?r=")[1].length !== 0) {

        let response = await reqwest({
          url: 'https://us-central1-vandyfood.cloudfunctions.net/getUpdateData',
          crossOrigin: true,
          method: 'POST',
          data: {
            auth: decodeURIComponent(window.location.search.split("?r=")[1])
          }
        });

        // TESTING: let response = {response: {morning: false}};

        console.log(response);

        if (response.error) {
          setError(response.error);
          return;
        }

        response = response["response"];

        setTimeout(() => {
          if (!response["permanentBypass"]) {
            response["permanentBypass"] = [];
          }

          if (!response["temporaryBypass"]) {
            response["temporaryBypass"] = [];
          }

          setSchedule(response);
        }, 250);
      } else {
        setError("Please use a valid update link!");
      }

      return;
    }
    efRun();
  }, []);

  const submit = async () => {
    setSendLoader(true);

    console.log(schedule);

    let response = await reqwest({
      url: 'https://us-central1-vandyfood.cloudfunctions.net/setUpdateData',
      crossOrigin: true,
      method: 'POST',
      data: {
        auth: decodeURIComponent(window.location.search.split("?r=")[1]),
        schedule: JSON.stringify(schedule)
      }
    });

    if (response["error"]) {
      setSendLoader(false);
      setError("We had an error sending your data.");
      return;
    }

    setSuccess(true);
  }

  return (
    <div className={styles.globalFont}>
        <Container
          className={styles.mainContainer}
          id='mainContainer'
        >
          {((!schedule && !error) || (sendLoader && !success)) && (
            <div>
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <br />
              <center><div>One second, we're getting your data...</div></center>
            </div>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              <h4 className="alert-heading">Yikes!</h4>
              <p>{error} Please try again by using the exact link we texted you.</p>
            </div>
          )}
          {success && (
            <div className="alert alert-success" role="alert">
              <h4 className="alert-heading">You're good to go!</h4>
              <p>Your information has been updated. If you ever need to update it again, just click on the link we texted you again!</p>
            </div>
          )}
          {schedule && !error && !sendLoader && (
            <div>
          <Row>
            <Col className={styles.colCenter}>
              <div className={styles.mainTitle}><b>Hi there!</b></div>
            </Col>
          </Row>
          <div class="container">
          <Row>
            <Col>
            <p>Select a day of the week below to skip donations on, just for this week. (If you pick a day of the week before today, it won't carry over.) <br /><span className={styles.smallText}>Once you've selected a date, you can choose to permanently opt-out of donating on that day -- but unless you're sure you'll always have extra orders on this day, we encourage you not to do this.</span></p>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col>
            {daysOfWeek.map((day, index) => (
              <div>
                <div class="row">
                  <div class="col-sm-4">
                    <input defaultChecked={getState("temporaryBypass", index + 1)} onChange={(e) => {setCheckState(e, "temporaryBypass", index + 1)}}  type="checkbox" id={day + 't'} name="vehicle1" value="Bike" />
                    <label htmlFor={day + 't'}>&nbsp;&nbsp;{day}</label><br />
                  </div>
                  {getState("temporaryBypass", index + 1) && (
                  <div class="col-sm-8">
                    <input defaultChecked={getState("permanentBypass", index + 1)} onChange={(e) => {setCheckState(e, "permanentBypass", index + 1)}} type="checkbox" id={day + 'p'} name="vehicle1" value="Bike" />
                    <label htmlFor={day + 'p'}>&nbsp;&nbsp;NEVER donate on {day}</label><br />
                  </div>
                  )}
                </div>
              </div>
            ))}
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Button
                id='goBtn'
                onClick={submit}
              >
                Save Settings
              </Button>
            </Col>
          </Row>
          </div>
          </div>
          )}
        </Container>
      <br /><br /><br /><br /><br /><br />
    </div>
  )
}

export default Update

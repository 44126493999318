import React, { Component } from 'react'
import './App.scss'
import { Router, Route, Switch } from 'react-router-dom'
import history from './history'

import Main from './components/Main'
import Update from './components/Update'
import Links from './components/Links'

class App extends Component {
  render () {    
    return (
      <div>
        <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap" rel="stylesheet" />
        <Router history={history}>
          <Switch>
            <Route exact path='/' component={Main} />
            <Route exact path="/u" component={Update} />
            <Route exact path="/links" component={Links} />
          </Switch>
        </Router>
      </div>
    )
  }
}

export default App

//import * as firebase from 'firebase/app';
//not a clue what I'm doing but this is what a kind man on stack overflow says to (https://stackoverflow.com/questions/64545862/upgrade-to-firebase-js-8-0-0-attempted-import-error-app-is-not-exported-from)
import firebase from 'firebase/app'
import 'firebase/database';

firebase.initializeApp({
    "apiKey": "AIzaSyDOUe8DLW0qXROOIjKlXjSqn43uYGweVeQ",
    "appId": "1:606030307483:web:23e932d6737d87ee15663e",
    "authDomain": "vandyfood.firebaseapp.com",
    "databaseURL": "https://vandyfood.firebaseio.com",
    "messagingSenderId": "606030307483",
    "projectId": "vandyfood",
    "storageBucket": "vandyfood.appspot.com"
  });

  export default firebase;
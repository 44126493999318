import React from 'react'

import styles from './links.module.scss'

const Links = () => {

  return (
    <div className={styles.testBody}>
    <img
                className={styles.tempLogo}
                src={
                  'https://firebasestorage.googleapis.com/v0/b/vandyfood.appspot.com/o/SwipeForACause.png?alt=media&token=e784956f-c2c9-4932-905d-87a17925eca9'
                }
                alt='Vanderbilt Logo'
              ></img>
    <div className={styles.links}>
    
            <div className={styles.links__item}>
                <a className={styles.links__item_promo} href="https://vanderbilthustler.com/37504/featured/guest-editorial-vanderbilt-bring-back-swipes-for-a-cause/"
                    target="_blank">Op-Ed on The Vanderbilt Hustler</a>
            </div>
            <div className={styles.links__item}>
                <a className={styles.links__item_past_event} href="https://change.org/vandyswipes"
                    target="_blank">Petition on Change.org</a>
            </div>
            <div className={styles.links__item}>
              <a className={styles.links__item_featured} href="https://swipesforacause.org"
                  target="_blank">Open Letter to Vanderbilt Adminstration</a>
                  </div>
    
        </div>
  </div>
  )
}

export default Links
